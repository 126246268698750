/**
 * `debug()` --> boolean
 *
 * Returns `true` if we're in debug mode; false otherwise
 */
module.exports = debug

function debug() {
  return window.DEBUG ? true : false
}
