/**
 * `pageContext()`
 *
 * Returns page-context object from Django
 *
 */
import {warningResolver} from 'airship/utils/resolvers'
import {Resolver} from 'airship/utils/resolvers/types'

let cachedContext: unknown = null

export default function pageContext<T>(
  resolver?: Resolver<T>,
  /** For unit testing only: skip the cache and fetch from the DOM again. */
  ignoreCache = false
): T {
  if (!ignoreCache && cachedContext) return cachedContext as T

  const el = document.getElementById('js-page-context')

  if (!el) {
    return {} as T
    // throw new Error('js-page-context element not found')
  }

  const json = el.innerText.trim()

  const context = JSON.parse(JSON.parse(json))

  // If a resolver is provided, warn if it doesn't pass
  if (resolver) {
    warningResolver(resolver)(context)
  }

  cachedContext = context
  return context
}
