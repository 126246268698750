import {Issue} from './types'

export class ResolveError extends Error {
  public issues: Issue[]
  public constructor(issues: Issue[]) {
    const s = issues.length === 1 ? '' : 's'
    super(
      `ResolveError: ${issues.length} issue${s} found.\n` +
        JSON.stringify(issues)
    )
    this.issues = issues

    Object.setPrototypeOf(this, ResolveError.prototype)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResolveError)
    }
    this.name = this.constructor.name
  }
}

export function errorToIssues(
  error: Error,
  location: string,
  value?: unknown
): Issue[] {
  if (error instanceof ResolveError) {
    return error.issues.map((issue) => {
      const newLocation = [location, issue.location]
        .filter((s) => s !== '')
        .join('.')
      return {
        ...issue,
        location: newLocation,
      }
    })
  } else {
    return [{message: error.message, location, value}]
  }
}
